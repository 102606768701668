<template>
  <div class="header">
    <div class="mobile" :class="{ active: isMenu }">
      <nav>
        <router-link to="/" @click="isMenu = false">
          Головна
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
        </router-link>
        <router-link to="/about" @click="isMenu = false">
          Про нас
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
        </router-link>
        <router-link to="/contacts" @click="isMenu = false">
          Контакти
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
        </router-link>
      </nav>

      <div class="mobile-close" @click="isMenu = false">
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="wrap">
      <div class="logo">
        <img src="@/assets/logo.svg" alt="logo" />
      </div>
      <nav>
        <svg id="stroke" xmlns="http://www.w3.org/2000/svg" width="0" height="0">
          <defs>
            <path
              id="line"
              d="M2 2c49.7 2.6 100 3.1 150 1.7-46.5 2-93 4.4-139.2 7.3 45.2-1.5 90.6-1.8 135.8-.6"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              vector-effect="non-scaling-stroke"
            />
          </defs>
        </svg>

        <router-link to="/">
          Головна
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
        </router-link>
        <router-link to="/about">
          Про нас
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
        </router-link>
        <router-link to="/contacts">
          Контакти
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
          <svg class="button-stroke" viewBox="0 0 154 13">
            <use href="#line"></use>
          </svg>
        </router-link>
      </nav>

      <div class="social">
        <a href="https://www.facebook.com/Sokolyatko" target="_blank">
          <img src="@/assets/facebook.svg" alt="facebook" />
        </a>
      </div>

      <div class="mobile-btn" @click="isMenu = true">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref } from "vue";

  export default {
    name: "Header",

    setup() {
      const isMenu = ref(false);

      return { isMenu };
    },
  };
</script>
<style lang="scss" scopd>
  .header {
    position: relative;
    width: 100%;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    z-index: 99;

    .button-stroke {
      display: block;
      width: calc(100% - 40px);
      height: 20px;
      stroke: #fff;
      position: absolute;
      left: 20px;
      bottom: -10px;
      stroke-width: 4;

      &:nth-child(2) {
        stroke-dasharray: 650px;
        stroke-dashoffset: 650px;
        stroke: $acs;
        stroke-width: 5;
        transition: stroke-dashoffset 800ms ease-out;
      }
    }

    .mobile {
      position: fixed;
      display: grid;
      place-items: center;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #fff;
      transform: translateY(-120%);
      transition: 0.3s ease-in-out;

      &.active {
        transform: translateY(0%);
      }

      nav {
        display: grid;
        place-items: center;

        a {
          position: relative;
          display: block;
          color: #333333;
          min-width: 154px;
          text-align: center;
          font-size: 1.5em;
          margin: 16px 0;

          &:hover {
            color: $acs;

            .button-stroke:nth-child(2) {
              stroke-dashoffset: 0;
            }
          }
        }
      }

      .mobile-close {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        top: 2vh;
        right: 3vw;
        cursor: pointer;

        &:hover span {
          background: $acs;
        }

        span {
          position: absolute;
          width: 35px;
          height: 4px;
          background: #333333;
          border-radius: 2px;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .wrap {
      height: 100px;
      display: grid;
      grid-template-columns: 125px 1fr 125px;
      place-items: center;
      padding: 0 2vw;

      .logo {
        background: #fff;

        img {
          height: 40px;
        }
      }

      nav {
        display: flex;

        @media only screen and (max-width: 768px) {
          display: none;
        }

        a {
          position: relative;
          display: block;
          color: #333333;
          min-width: 154px;
          text-align: center;

          &:hover {
            color: $acs;

            .button-stroke:nth-child(2) {
              stroke-dashoffset: 0;
            }
          }
        }
      }

      .social {
        display: flex;
        a {
          margin: 0 8px;
          background: #fff;

          &:hover {
            filter: brightness(1.3);
          }

          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .mobile-btn {
        display: none;
        place-items: center;
        width: 50px;
        height: 50px;
        cursor: pointer;
        padding: 10px 0;
        justify-self: end;

        @media only screen and (max-width: 768px) {
          display: grid;
        }

        span {
          width: 35px;
          height: 2px;
          border-radius: 2px;
          background: #333333;
        }
      }
    }
  }
</style>
