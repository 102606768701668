<template>
  <div class="facebook" id="facebook">
    <div class="wrap">
      <h2 data-aos="fade" data-aos-delay="300">Ми у соцмережах</h2>
      <div
        class="widget"
        data-aos="fade-down"
        data-aos-delay="300"
        data-aos-duration="800"
        data-aos-anchor-placement="top-center"
      >
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSokolyatko&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=false&hide_cover=true&show_facepile=true&appId=297664035001940"
          width="340px"
          height="500"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .facebook {
    .wrap {
      margin-top: 64px;

      .widget {
        display: grid;
        place-items: center;
        overflow-x: hidden;
      }
    }
  }
</style>
