<template>
  <div class="home">
    <Hero />
    <Propose />
    <Facebook />
  </div>
</template>

<script>
  import Hero from "../components/home/Hero.vue";
  import Propose from "../components/home/Propose.vue";
  import Facebook from "../components/home/Facebook.vue";

  export default {
    name: "Home",
    components: {
      Hero,
      Propose,
      Facebook,
    },
  };
</script>

<style lang="scss" scoped>
  .home {
    background: $acs;
  }
</style>
