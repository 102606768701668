<template>
  <div class="footer">
    <div class="wrap">
      <img src="@/assets/logo_brd.png" alt="berdychiv" />

      <p>Дитячий заклад оздоровлення та відпочинку «Соколятко» Бердичівської міської ради 2021р.</p>
    </div>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .footer {
    background: #6e4b36;

    .wrap {
      display: grid;
      grid-template-columns: 50px 1fr;
      place-items: center;
      grid-gap: 16px;

      img {
        height: 60px;
      }

      p {
        color: #fff;
        font-size: 0.8em;
      }
    }
  }
</style>
