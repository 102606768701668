import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    meta: { title: "Про нас" },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    meta: { title: "Контакти" },
    component: () => import("../views/Contacts.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Missing",
    meta: { title: "404" },
    component: () => import("../views/Missing.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title + " | Соколятко Бердичів" : "Соколятко | Бердичів";
  next();
});

export default router;
