<template>
  <div class="hero">
    <div class="background"></div>
    <div class="slogan">
      <img src="@/assets/logo.svg" alt="logo" />
      <h1>Ми чекаємо на тебе!</h1>
    </div>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .hero {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 100px);
    background: #dfba5d;
    // background: #f8d978;

    .background {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: url(../../assets/hero.svg) no-repeat center bottom;
      background-size: cover;
    }

    .slogan {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 500px;
      padding-top: 15vh;
      background: transparent;
      // z-index: 1;

      img {
        max-width: 300px;
        height: 100px;
      }

      h1 {
        color: #333333;
        margin: 0;
        // -webkit-text-stroke: 2px #fff;
      }
    }
  }
</style>
