<template>
  <div class="propose">
    <div class="wrap">
      <h2 data-aos="fade">Наші переваги</h2>

      <div class="sections">
        <img
          src="@/assets/prop_1.svg"
          alt="prop"
          data-aos="fade-left"
          data-aos-duration="800"
          data-aos-anchor-placement="top-center"
        />
        <div class="description" data-aos="fade-right" data-aos-duration="800" data-aos-anchor-placement="top-center">
          <h3>Насичений графік</h3>
          <p>
            Чудово і з користю провести час в компанії з друзями за різноманітними заняттями. Пізнавай світ, вчись та
            розвивайся разом з нами!
          </p>
        </div>
        <div class="description" data-aos="fade-left" data-aos-duration="800" data-aos-anchor-placement="top-center">
          <h3>Різноманітні активності</h3>
          <p>
            Фізичні та розумові. Тут не буде часу сумувати.
          </p>
        </div>
        <img
          src="@/assets/prop_2.svg"
          alt="prop"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-anchor-placement="top-center"
        />
        <img
          src="@/assets/prop_3.svg"
          alt="prop"
          data-aos="fade-left"
          data-aos-duration="800"
          data-aos-anchor-placement="top-center"
        />
        <div class="description" data-aos="fade-right" data-aos-duration="800" data-aos-anchor-placement="top-center">
          <h3>Чудовий колектив</h3>
          <p>
            Тобі завжди допоможуть у будь-якій ситуації та створять відмінні умови для цікавого і насиченого відпочинку.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .propose {
    // height: 1200px;
    margin-top: 64px;

    .wrap {
      h2 {
        color: #fff;
      }

      h3 {
        color: #83180f;
      }

      .sections {
        position: relative;
        display: grid;
        place-items: center;
        grid-gap: 32px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);

        img {
          max-width: 500px;
          margin: -70px 0;
        }
      }
    }
  }
</style>
